.header {
  padding: 24px;
  background: #fff;
  .logo {
    max-width: 100px;
    max-height: 40px;
    padding-right: 20px;
  }
  .title {
    flex-grow: 1;
  }
}
