.form-container {
  form {
    & > * {
      margin-bottom: 20px;
    }
    .buttons {
      margin-top: 20px;
      & > * {
        margin-right: 20px;
      }
    }
  }
}
