.login-container {
  $padding: 40px;
  max-width: 400px;
  width: calc(90% - #{$padding * 2});
  text-align: center;
  margin: 120px auto;
  padding: $padding;
  .title {
    margin-bottom: 30px;
  }
  form {
    margin-bottom: -30px;
    & > * {
      margin-bottom: 30px;
    }
  }
}
